import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import { JobFeature } from "../components/job-components"
import SuccessStory from "../components/success-story"

const SuccessStoryQapture = ({ data }) => {
  return (
    <SuccessStory
      title="GDPR-compliant digital twins"
      customerName="qapture"
      customerLocation="Linz, Austria"
      customerDescription="qapture brings assets from the analog to the digital world, with the mission to rethink and advance existing solutions in the field of reality capture."
      customerIndustries="Digital Twin, Surveying, Mapping"
      link="https://qapture.at/"
      logo=""
      style={{
        background: "url(/images/qapture-success-story.png) no-repeat",
        backgroundPositionY: "center",
        backgroundSize: "cover",
      }}
    >
      <h2 className="my-3">Challenge</h2>
      <p>
        The construction of the “Neue Eisenbahnbrücke” bridge in Linz represents a crucial infrastructure project for
        the city of Linz, Austria. It requires maintenance work, checks, and tests at different annual intervals are
        necessary. To make this process more efficient, it was decided to create a detailed and high-resolution digital
        twin in order to rely on real-time data and analyze it over time.
      </p>
      <p>
        As part of the{" "}
        <a
          href="https://www.ioeb.at/digitaler-zwilling-eisenbahnbruecke-linz"
          target="_blank"
          rel="noopener noreferrer"
        >
          "Digitales Linz"
        </a>{" "}
        program, qapture combined different systems to create an accurate digital twin of the “Neue Eisenbahnbrücke”
        bridge: a drone equipped with a GeoSlam laserscanner, a NavVis VLX, and a Boston Dynamics SPOT with a FARO
        laserscanner mounted on its back. The resulting digital twin serves many different stakeholders and use-cases,
        e.g. evidence preservation, remote inspections, as well as planning of maintenance or renovation work.
      </p>
      <p>
        To make such data available to all stakeholders and - potentially in the future - open to all citizens, persons
        and cars had to be blurred to comply with the GDPR. Furthermore, a solution had to be found to export and import
        images from FARO SCENE, an expert software suite for laserscanning professionals.
      </p>

      <figure className="figure">
        <Img fluid={data.qaptureImage.childImageSharp.fluid} className="rounded my-3" />
        <figcaption className="figure-caption">
          The digital twin of the "Neue Eisenbahnbrücke" bridge in Linz, by qapture GmbH.
        </figcaption>
      </figure>

      <h2 className="my-3">Solution</h2>
      <p>
        qapture and Celantur worked together on a solution to {" "}
        <a href="https://doc.celantur.com/tutorials/faro-blurring-workflow" target="_blank" rel="noopener noreferrer">
          export and edit FARO laser scanner images
        </a>
        . Using Celantur’s{" "}
        <a href="https://app.celantur.com/" target="_blank" rel="noopener noreferrer">
          {" "}
          cloud service{" "}
        </a>
        , faces and license plates were blurred.
      </p>

      <h2 className="my-3">Celantur's contribution</h2>
      <JobFeature>
        Hundreds of high-resolution panorama images were automatically anonymized right after being collected
      </JobFeature>
      <JobFeature>Personal data is protected when making data available to the city of Linz</JobFeature>
    </SuccessStory>
  )
}

export default SuccessStoryQapture

export const query = graphql`
  query {
    qaptureImage: file(relativePath: { eq: "qapture-success-story.png" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
